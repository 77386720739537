.home {
    &__title {
        font-size: $font-size--1;
        padding-left: 0;
        display: inline-flex;
        margin: 0 0 45px;
        width: fit-content;

        &:before {
            left: auto;
            right: -45px;
            top: auto;
            bottom: -15px;
        }

        &--white {
            color: $color-white;
        }

        &--green {
            &:before {
                background: url(/wp-content/themes/pipriac/assets/src/images/sprite-images/shapes/Ligne-titre-vert.svg) no-repeat;
                background-size: contain;
            }
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
    }

    .button {
        height: 40px;
    }

    &__nav {
        display: none;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: 90px;
        margin-top: 5px;

        &--visible {
            display: flex;
        }

        .swiper-button {
            width: 40px;
            height: 40px;
            margin: 0 ;
            border-radius: $border-radius--round;
            padding: 0;
            top: 0;

            &:after {
                display: none;
            }
            &-prev {
                left: 0;
            }
            &-next {
                right: 0;
            }

            svg {
                fill: $color-dark;
                width: 40px;
                height: 40px;
            }
        }
    }

    //======================================================================================================
    // SlideShow
    //======================================================================================================
    .alu {
        position: relative;

        &__shape {
            position: absolute;
            bottom: 24px;
            left: 0;
            height: 150px;
            width: 100%;
            background: url(/wp-content/themes/pipriac/assets/src/images/sprite-images/shapes/BG-ALU.svg) no-repeat;
            z-index: 5;
        }

        > .container {
            position: relative;
        }

        .container {
            &--content {
                position: relative;
                height: 100%;
            }

            &--nav {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                z-index: 5;
                pointer-events: none
            }
        }

        .swiper-container {
            overflow: inherit;
            z-index: inherit;
        }

        .slideshow {
            position: relative;
            height: 550px; 
            width: 100%;

            &--video {
                .alu__shape {
                    bottom: 0;
                }
            }

            &__slide {
                padding-bottom: 25px;
            }

            img, video {
                position: absolute;
                object-fit: cover;
                object-position: top;
                width: 100%;
                height: 100%;
            }

            &__container {
                display: inherit;
                position: relative;
                width: 100%;
                height: 100%;
            }

            &__content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;
                position: absolute;
                right: 30px;
                bottom: -25px;
                width: 400px;
                height: auto;
                padding: 15px 20px;
                background: $color-dark;
                color: $color-white;
                z-index: 6;

                &:after {
                    content: '';
                    display: inline-block;
                    right: 20px;
                    width: 12px;
                    height: 12px;
                    background: url(/wp-content/themes/pipriac/assets/src/images/sprite-images/shapes/triangle.svg) no-repeat;
                    background-size: contain;
                    flex: none;
                }
            }

            &__title {
                margin: 0;
                font-size: 1.25rem;
                line-height: 1.5rem;
            }

            &--video {
                img {
                    display: none;
                }
            }
        }

        &__nav {
            margin: 420px 0 auto auto;

            .swiper-button {
                pointer-events: initial;
                background-color: $color-second;
                border-color: $color-second;

                &:hover {
                    background-color: $color-dark;
                    border-color: $color-dark;

                    svg {
                        fill: $color-white;
                    }
                }
            }
        }
    }

    //======================================================================================================
    // Accès rapides
    //======================================================================================================
    .access {
        position: relative;
        z-index: 1;
        margin-top: -10px;
        padding-bottom: 75px;

        &__list {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 170px;
            height: 155px;
            box-shadow: $shadow;
            border-radius: $border-radius--home;
            background-color: $color-white;
            overflow: hidden;

            &:hover {
                box-shadow: 0 0 0 1px $color-main;
            }

            &:nth-child(even) {
                &:hover {
                    box-shadow: 0 0 0 1px $color-second;
                }

                .access {
                    &__svg-container {
                        &:after {
                            background: url(/wp-content/themes/pipriac/assets/src/images/sprite-images/shapes/Ligne-accesrapides-rose.svg) no-repeat;
                        }
                    }
                }
            }

            &--cni {
                display: none;
            }
        }

        &__svg-container {
            display: flex;
            flex-direction: column;

            &:after {
                content: '';
                display: block;
                margin: 15px auto;
                height: 5px;
                width: 45px;
                background: url(/wp-content/themes/pipriac/assets/src/images/sprite-images/shapes/Ligne-accesrapides.svg) no-repeat;
                background-size: contain;
            }

            svg {
                width: 50px;
                height: 50px;
            }
        }

        &__label {
            color: $color-text;
            text-transform: uppercase;
            text-align: center;
            line-height: 1.188rem;
            letter-spacing: 0.05rem;
        }
    }

    //======================================================================================================
    // Agenda
    //======================================================================================================
    .events {
        position: relative;
        background-color: $color-light;
        padding: 0 0 150px;
        
        > .container {
            position: relative;
            padding-top: 60px;

            &:after {
                content: '';
                position: absolute;
                top: 220px;
                right: -510px;
                width: 750px;
                height: 750px;
                background: url(/wp-content/themes/pipriac/assets/src/images/sprite-images/shapes/Rond-Agenda.svg) no-repeat;
                background-size: contain;
            }

            &:before {
                content: "";
                position: absolute;
                top: -24px;
                height: 25px;
                width: 2000px;
                background: url(/wp-content/themes/pipriac/assets/src/images/sprite-images/shapes/vague-agenda-2.svg) no-repeat;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .swiper-wrapper {
            display: flex;
        }

        .swiper-slide {
            display: flex;
            width: 370px;
            margin-right: 30px;

            &:last-child {
                margin-right: 0;
            }
        }

        .swiper-button {
            &:hover {
                background-color: $color-dark;

                svg {
                    fill: $color-white;
                }
            }
        }

        &__item {
            &:hover {
                .events {
                    &__item {
                        &__container-img {
                            &:after {
                                transform: rotate(90deg);
                            }
                        }

                        &__date {
                            background-color: $color-main;

                            p {
                                color: $color-text;
                            }
                        }
                    }
                }
            }

            &__container-img {
                position: relative;
                background: url(/wp-content/themes/noyau/assets/images/icon-events.png) $color-gray center no-repeat;
                width: 370px;
                height: 280px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: -25px;
                    left: 0;
                    width: 12px;
                    height: 12px;
                    background: url(/wp-content/themes/pipriac/assets/src/images/sprite-images/shapes/triangle.svg) no-repeat;
                    background-size: contain;
                    transition: $duration;
                }
            }

            &__title {
                line-height: 1.875rem;
                color: $color-dark;
                margin: 20px 0 0;
            }

            &__date {
                width: 280px;
                height: 70px;
                background-color: $color-dark;
                margin: -45px auto auto 70px;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                transition: $duration;

                p {
                    font-family: $font-family--heading;
                    text-align: center;
                    color: $color-white;
                    font-size: 1.25rem;
                    line-height: 1.563rem;
                    font-style: italic;
                    text-transform: uppercase;
                    font-weight: $font-weight-light;
                    transition: $duration;
                }
            }
        }

        &__template {
            display: flex;
            justify-content: flex-start;
            flex-direction: row-reverse;
            position: relative;
            z-index: 1;
            margin: 40px 0 0;

            &__btn {
                width: 300px;
                margin: 0 0 0 20px;

                &--prop {
                    background-color: $color-dark;
                    border-color: $color-dark;
                    color: $color-white;

                    &:hover {
                        background-color: $color-light;
                        border-color: $color-dark;
                        color: $color-dark;
                    }
                }
            }
        }
    }
    
    //======================================================================================================
    // Actualités
    //======================================================================================================
    .posts {
        position: relative;
        background-color: $color-dark;
        padding: 50px 0 0;

        > .container {
            position: relative;
            padding-bottom: 60px;

            &:after {
                content: "";
                position: absolute;
                bottom: -25px;
                height: 25px;
                width: 2000px;
                background: url(/wp-content/themes/pipriac/assets/src/images/sprite-images/shapes/vague-actu-2.svg) no-repeat;
                right: 50%;
                transform: translateX(50%);
            }
        }

        &__shape {
            position: absolute;
            top: -195px;
            left: 0;
            height: 195px;
            width: 100%;
            background: url(/wp-content/themes/pipriac/assets/src/images/sprite-images/shapes/BG-Actu.svg) no-repeat;
        }

        &__nav {
            position: absolute;
            top: -90px;
            right: 0;

            .swiper-button {
                background-color: $color-second;
                border-color: $color-second;

                &:hover {
                    background-color:$color-white;
                    border-color: $color-white;
                }
            }
        }

        &__list {
            display: flex;
        }

        &__list-small {
            position: relative;
            width: 630px;
        }

        .swiper-wrapper {
            display: flex;
        }

        .swiper-slide {
            display: flex;
            width: 300px!important;
            margin-right: 30px;

            .posts {
                &__item {
                    &__container-img {
                        width: 300px;
                        height: 280px;
                    }

                    &__content {
                        padding: 20px 0 0;
                    }

                    &__title,
                    &__intro,
                    &__more {
                        color: $color-white;
                    }
                    
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &__item {
            display: block;

            &:hover {
                .posts {
                    &__item {
                        &__container-img {
                            img {transform: scale(1.1);}
                        }
        
                        &__more {
                            padding-right: 20px;
                        }
                    }
                }
            }

            &--0 {
                width: 500px;
                margin-right: 40px;
                
                .posts {
                    &__item {
                        &__container-img {
                            width: 500px;
                            height: 380px;
                        }

                        &__content {
                            background-color: $color-white;
                            padding: 25px;
                            width: 350px;
                            margin: -100px 30px auto auto;
                        }

                        &__title,
                        &__intro,
                        &__more {
                            color: $color-text;
                        }
                    }
                }
            }

            &__container-img {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-gray center no-repeat;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transform: scale(1);
                    transition: .35s;
                }
            }

            &__content {
                position: relative;
                box-sizing: border-box;
                transition: all $duration;
            }

            &__title {
                line-height: 1.875rem;
                margin: 0 0 10px;
            }

            &__intro {
                line-height: 1.375rem;
            }

            &__more {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 10px;
                padding-right: 0;
                transition: $duration;

                &:before {
                    content: '';
                    display: inline-block;
                    vertical-align: middle;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6px 0 6px 12px;
                    border-color: transparent transparent transparent $color-second;
                    margin-right: 10px;
                }
            }
        }

        &__template {
            display: flex;
            justify-content: flex-end;
            margin-top: 40px;

            &__btn {
                margin: 0;
                width: 300px;
                background-color: $color-white;
                border-color: $color-white;
                color: $color-text;

                &:hover {
                    background-color: $color-second;
                    border-color: $color-second;
                    color: $color-text;
                }
            }
        }
    }

    //======================================================================================================
    // Admin Area
    //======================================================================================================
    .admin-area {
        padding: 95px 0 0;

        &__wrapper {
            position: relative;
            display: flex;

            &:before {
                content: '';
                position: absolute;
                top: -160px;
                left: -230px;
                width: 330px;
                height: 330px;
                background: url(/wp-content/themes/pipriac/assets/src/images/sprite-images/shapes/Rond-Zonelibre.svg) no-repeat;
                background-size: contain;
                z-index: -1;
            }
        }

        &__image {
            width: 500px;
            height: 250px;
            flex: none;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }
        }

        &__content {
            position: relative;
            margin-top: 45px;
            padding: 55px 50px 70px 70px;
            min-height: 250px;

            &:before {
                content: '';
                height: 100%;
                width: calc(100% + 1000px);
                background-color: $color-light;
                position: absolute;
                left: -100px;
                top: 0;
                z-index: -1;
            }
        }

        &__title {
            margin: 0 0 10px;
            padding-left: 0;

            &:before {
                display: none;
            }
        }

        &__description {
            font-size: 1.25rem;
            line-height: 1.563rem;
            font-weight: $font-weight-semibold;
        }
    }

    //======================================================================================================
    // Kiosque
    //======================================================================================================
    .kiosque-map {
        padding: 40px 0 80px;
        overflow: hidden;

        > .container {
            display: flex;
        }
    }

    .kiosque {
        margin-right: 100px;

        .doc {
            display: flex;

            &__container-img {
                width: 270px;
                height: 380px;
                background: url(/wp-content/themes/noyau/assets/images/icon-documents.png) no-repeat center;
                flex: none;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 0 0 0 30px;
                width: 300px;
            }

            &__info {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                flex: 1;
            }

            &__title {
                margin: 0;
                color: $color-text;
                line-height: 1.875rem;
            }

            &__details {
                &__date {
                    display: block;
                }
                &__extension {
                    text-transform: uppercase;
                }
            }

            &__btn {
                width: 40px;
                height: 40px;
                background-color: $color-second;
                border-radius: $border-radius--round;
                margin-right: 15px;

                &:hover {
                    background-color: $color-dark;

                    svg {
                        fill: $color-white;
                    }
                }

                svg {
                    width: 40px;
                    height: 40px;
                }
            }

            &__buttons {
                display: flex;
            }
        }

        &__btn-all {
            width: 270px;
            margin: 20px 0 0;
        }
    }

    //======================================================================================================
    // Notre ville
    //======================================================================================================
    .map {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex: 1;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: -203px;
            left: 113px;
            width: 655px;
            height: 864px;
            background: url(/wp-content/themes/pipriac/assets/src/images/sprite-images/shapes/Carte.svg) no-repeat;
            background-size: contain;
            z-index: -1;
        }

        &__title {
            margin: 0 0 60px;

            &:before {
                right: -25px;
                bottom: -22px;
            }
        }

        &__template-btn {
            padding: 0;
            margin: 0;
            width: 270px;
            background-color: $color-dark;
            border-color: $color-dark;
            color: $color-white;

            &:hover {
                background-color: $color-second;
                border-color: $color-second;
                color: $color-text;
            }
        }
    }
}



//======================================================================================================
// Responsive
//======================================================================================================
// min 1500
@media screen and (min-width: 1500px) {
    .home {
        .alu {
            &__shape {
                height: 200px;
                bottom: 20px;
            }
        }
    }
}

// 2000
@media screen and (max-width: 2000px) {
    .home {
        .alu,
        .posts {
            &__shape {
                background-size: cover;
            }
        }
    }
}

// 1200
@media screen and (max-width: $large) {
    .home {
        .alu {  
            &__shape {
                height: 120px;
            }
        
            .slideshow {
                height: 440px; 

                &__content {
                    right: 20px;
                }
            }
        
            &__nav {
                margin: 315px 0 auto auto;
            }
        }

        .access {
            &__item {
                width: 140px;
            }
        }

        .events {
            >.container {
                &:before {
                    left: auto;
                    transform: translateX(0);
                    right: -450px;
                }
            }

            .swiper-slide {
                width: 300px;
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }
            }

            &__item {
                &__container-img {
                    width: 300px;
                    height: 225px;

                    &:after {
                        bottom: -30px;
                    }
                }

                &__date {
                    width: 235px;
                    height: 70px;
                    margin: -40px 15px auto auto;

                    p {
                        font-size: 1.125rem;
                    }
                }
            }
        }

        .posts {     
            >.container {
                &:after {
                    right: auto;
                    transform: translateX(0);
                    left: -400px;
                }
            }

            &__shape {
                top: -155px;
                height: 155px;
            }

            &__list-small {
                width: 510px;
            }

            .swiper-slide {
                width: 245px!important;
                margin-right: 20px;
        
                .posts {
                    &__item {
                        &__container-img {
                            width: 245px;
                            height: 225px;
                        }                    
                    }
                }
            }

            &__item {    
                &--0 {
                    width: 400px;
                    margin-right: 30px;
                    
                    .posts {
                        &__item {
                            &__container-img {
                                width: 400px;
                                height: 304px;
                            }
        
                            &__content {
                                margin: -70px auto auto;
                            }
                        }
                    }
                }
            }
        }

        .admin-area {
            &__image {
                width: 400px;
                height: 200px;
            }

            &__content {
                margin-top: 40px;
                min-height: 190px;
                padding: 35px 0px 35px 40px;

                &:before {
                    left: -80px;
                }
            }
        }
        
        .kiosque {
            margin-right: 50px;

            .doc {   
                &__container-img {
                    width: 220px;
                    height: 310px;
                }
        
                &__content {
                    padding: 0 0 0 20px;
                    width: 240px;
                }

                &__title {
                    margin: 0 0 15px;
                }
        
                &__info {
                    justify-content: flex-start;
                }

                &__details {
                    margin: 0 0 20px;
                }
            }
        
            &__btn-all {
                width: 220px;
                margin: 0;
                padding: 0;
            }
        }

        .map {    
            &:after {
                top: -175px;
                left: 110px;
                width: 620px;
                height: 720px;        
            }
        }
    }    
}


// 960
@media screen and (max-width: $medium) {
    .home {
        .alu {  
            &__shape {
                height: 95px;
            }
        
            .slideshow {
                height: 350px; 

                &--video {
                    video {
                        display: none;
                    }

                    img {
                        display: block;
                    }
                }

                &__content {
                    width: 300px;
                }
            }
        
            &__nav {
                margin: 190px 0 auto auto;
            }
        }

        .access {
            margin-top: 25px;

            &__list {
                flex-wrap: wrap;
            }

            &__item {
                margin-bottom: 25px;

                &--cni {
                    display: flex;
                    background-color: $color-main;
                    box-shadow: none;
                    width: 300px;

                    &:hover {
                        background-color: $color-white;
                    }

                    .access {
                        &__svg-container {
                            svg {
                                width: 70px;
                                height: 70px;
                                fill: $color-text;
                
                                path {
                                    fill: $color-text;
                                }
                            }
                
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .events {
            >.container {
                &:before {
                    right: -470px;
                }
            }

            &__template {
                justify-content: space-between;
                &__btn {
                    margin: 0;
                }
            }
        }

        .posts {     
            >.container {
                &:after {
                    left: -450px;
                }
            }

            &__shape {
                top: -125px;
                height: 125px;
            }

            &__list-small {
                width: 300px;
            }

            .swiper-slide {
                width: 300px!important;
                margin-right: 20px;
        
                .posts {
                    &__item {
                        &__container-img {
                            width: 300px!important;
                            height: 275px;
                        }                    
                    }
                }
            }

            &__item {
                &--0 {
                    width: 300px;
                    margin-right: 20px;
                    
                    .posts {
                        &__item {
                            &__container-img {
                                width: 300px;
                                height: 240px;
                            }
        
                            &__content {
                                padding: 15px;
                                width: 280px;
                                margin: -25px auto auto;
                            }
                        }
                    }
                }
            }
        }

        .admin-area {
            &__image {
                width: 300px;
                height: 200px;
            }

            &__content {
                padding: 30px 0px 30px 20px;

                &:before {
                    left: -60px;
                }
            }
        }

        .kiosque-map {
            padding: 60px 0 0;

            > .container {
                flex-direction: column;
            }
        }
        
        .kiosque {
            margin-right: 0;
            background-color: $color-white;

            .doc {   
                &__container-img {
                    width: 250px;
                    height: 350px;
                }
        
                &__content {
                    padding: 0 0 0 70px;
                    width: 370px;
                }

                &__title {
                    margin: 0 0 30px;
                }

                &__details {
                    margin: 0 0 40px;
                }
            }
        
            &__btn-all {
                width: 300px;
            }
        }

        .map {
            padding: 105px 0 160px;

            &:after {
                top: -105px;
                left: 265px;
            }

            &__template-btn {
                width: 300px;
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .home {
        &__title {
            font-size: 2.188rem;
        }

        .alu {
            padding-bottom: 30px;

            &__shape {
                height: 65px;
                bottom: auto;
                top: 170px;
            }

            &__slide {
                padding-bottom: 0;
            }

            .container--content {
                padding-top: 190px;
            }

            .container--nav {
                position: relative;
                height: auto;
            }

            &__nav {
                margin: 0 0 0 auto;
            }

            .slideshow {
                height: auto;
                min-height: 235px;

                img {
                    height: 235px;
                }

                &__content {
                    position: relative;
                    bottom: auto;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
        }

        .access {
            margin-top: 30px;

            &__item {
                margin-bottom: 20px;
            }
        }

        .events {
            >.container {
                &:after {
                    top: 180px;
                }
            }

            &__template {
                margin: 20px 0 0;
                flex-direction: column;

                &__btn {
                    margin: 20px 0 0;
                }
            }
        }

        .posts {    
            &__shape {
                top: -85px;
                height: 85px;
            }

            > .container {
                position: relative;
            }  

            &__nav {
                top: -60px;
            }

            &__list {
                flex-direction: column;
            }   

            .swiper-container {
                width: 100%;
                height: auto;
            }
            
            .swiper-slide {
                margin-right: 0;

                .posts {
                    &__item {
                        &__container-img {
                            width: 300px;
                            height: 275px;
                        }

                        &__content {
                            background-color: $color-dark;
                            padding: 20px 0 0;
                            margin: 0;
                        }

                        &__title,
                        &__intro,
                        &__more {
                            color: $color-white;
                        }
                    }
                }
            }

            &__item {
                &--0{
                    margin-bottom: 90px;
                    margin-right: 0;

                    .posts {
                        &__item {
                            &__container-img {
                                width: 300px;
                                height: 240px;
                            }

                            &__content {
                                background-color: $color-white;
                                width: 280px;
                                height: auto;
                                padding: 15px;
                                margin: -25px auto auto;
                            }

                            &__title,
                            &__intro,
                            &__more {
                                color: $color-text;
                            }
                        }
                    }
                }
            }
        
        }

        .admin-area {
            &__wrapper {
                flex-direction: column;
            }

            &__image {
                width: 300px;
                height: 200px;
            }

            &__content {
                padding: 30px 0px 40px;
                margin: 0 auto;

                &:before {
                    width: 350px;
                    height: calc(100% + 100px);
                    top: -100px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
        
        .kiosque {
            .doc {   
                flex-direction: column;

                &__content {
                    padding: 20px 0 0;
                    width: 100%;
                }

                &__title {
                    margin: 0 0 20px;
                }

                &__details {
                    margin: 0 0 20px;
                }
            }
        
            &__btn-all {
                margin: 30px 0 0;
            }
        }

        .map {
            padding: 60px 0 80px;

            &:after {
                top: 45px;
                left: 0;
                width: 500px;
                height: 650px;
            }

            &__title {
                margin: 0 0 390px;
            }
        }
    }
}
