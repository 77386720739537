.breadcrumb {
    position: relative;
    z-index: 5;
    background-color: $color-dark;
    margin: -30px auto 0 -10px;

    ul  {
        display: flex;
        flex-wrap: wrap;
        min-height: 42px;
        padding: 10px 20px;
    }

    li {
        display: flex;
        align-items: center;
        color: $color-white;
        padding: 0;

        a {
            color: $color-white;

            &:hover {
                text-decoration: underline;
                color: $color-white;
            }
        }

        &:after {    
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 0 6px 12px;
            border-color: transparent transparent transparent $color-main;
            margin: 0 10px;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 40px;
            width: 100%;
            height: 1px;
            box-shadow: 0px -1px 0 rgb(32 32 34 / 10%);
        }

        ul {
            min-height: 40px;
        }

        li {
            min-height: 40px;
        } 
    }

}
