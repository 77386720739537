@use "sass:list"; // https://sass-lang.com/documentation/breaking-changes/slash-div

.wrapper_map {
    position: relative;
    width: 100%;
    height: 600px;
}

.maps-container {
    display: flex;
    width: 100%;

    aside {
        position: relative;
    }

    .filter {
        &--side {
            width: 340px;
            height: 100%;
        }

        &__container {
            max-height: 610px;
            overflow-y: auto;
            padding-bottom: 0;
        }
    }

    #maps_publications {
        flex: 1 0 0;

        .wrapper_map {
            position: relative;
            width: 100%;
            height: 700px;
        }
    }

    .maps-button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 90px;

        button {
            margin: 0;
        }
    }
}

path.leaflet-interactive {
    stroke-width: 4;
}

.leaflet-popup-content-wrapper {
    .leaflet-popup-content {
        margin: 0;

        .geo-search {
            padding: 20px;
            font-size: $font-size--text;
            font-family: $font-family;
            color: $color-text;
        }

        .marker-popup {
            padding: 20px;

            p:empty {
                &::before {
                    display: none;
                }
            }

            &__title {
                margin-top: 10px;
                margin-bottom: 0;
                padding-bottom: 5px;
                padding-left: 0;
                border: 0;
                font-size: $font-size--4;
                color: $color-dark;

                &:before {
                    display: none;
                }
            }

            .marker-popup__category {
                margin-top: 0;
                font-family: $font-family;
                font-size: $font-size--text-xsmall;
                text-transform: uppercase;
            }

            &__location {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 10px 0 10px 70px;
                border-top: 1px solid $color-gray;

                p {
                    margin: 0;
                    font-weight: $font-weight--heading;
                    font-size: $font-size--text;
                    font-family: $font-family;
                    color: $color-text;
                }

                svg {
                    position: absolute;
                    left: 20px;
                    fill: $color-gray;
                }
            }

            &__content {
                display: flex;
                border-top: 1px solid $color-gray;
                padding: 15px 0 0;

                img {
                    margin-right: 15px;
                    height: 80px;
                }
            }

            &__description {
                p {
                    margin: 0 0 10px;
                    font-size: $font-size--text;
                    font-family: $font-family;
                    color: $color-text;
                }

                a {
                    color: $color-dark;

                    &:hover,
                    &:focus {
                        color: $color-white;
                    }
                }
            }
        }
    }
}

.leaflet-container {
    &#map {
        a.leaflet-popup-close-button {
        width: 38px;
        height: 26px;
        font: list.slash(1.625rem, $line-height) Tahoma, Verdana, sans-serif;
        color: $color-gray;
        font-weight: $font-weight-light;

            &:hover,
            &:focus {
                color: $color-main;
            }
        }
    }

}



section[data-cpt=maps] {
    .maps-container {
        margin-top: 20px;
    }
}

.leaflet-control-geosearch form .reset {
    border-radius: $border-radius--round;
    padding: 0;
    height: 30px;
    width: 30px;
}

.wrapper_map .leaflet-container {
    .leaflet-control-container .leaflet-control-geosearch form .reset {
        width: 40px;
        height: 40px;
        margin: 0;
        padding: 10px;
        background-color: $color-bg--transparent;
        border: $btn-border-width $btn-border-style $color-dark;
        border-radius: 0;
        font-size: 1.5rem;
        color: $color-dark;
    
        &:hover, &:focus {
            background-color: $color-dark;
            border-color: $color-dark;
            color: $color-white;
        }
    }
    
    a.leaflet-popup-close-button {
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        font: 1.25rem/0.875rem Tahoma,Verdana,sans-serif;
        font-weight: $font-weight-bold;
        color: $color-text;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .maps-container {
        flex-direction: column;

        .filter {
            &--side {
                width: 100%;
                height: 100%;
                max-height: inherit;
                overflow-y: hidden;
                padding-bottom: 60px;
            }

            &__container {
                margin-bottom: 25px;
                max-height: inherit;
            }
        }
        
        
    }

}

@media screen and (max-width: $small) {

    .wrapper_map .leaflet-container .leaflet-control-container .leaflet-control-geosearch form {
        width: 300px;
    }
}