#nav-main {
    position: relative;
    width: 100%;

    .menu__nav__list {
        display: flex;
        flex-wrap: wrap;
        padding-left: 280px;
        transition: $duration ease-out;
    } 

    .nav-main-item {

        &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
            .sub-menu {
                display: flex;
                justify-content: center;
            }
        }
        &:hover,
        &.active {
            .nav-main-link {
                &::before {
                    opacity: 1;
                }
            }
        }

        &:last-child {
            &:hover,
            &.active {
                .nav-main-link {
                    &:after {
                        border-color: $color-second;
                    }
                }
            }

           .nav-main-link {
            padding-left: 15px;

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 160px;
                    height: 60px;
                    border: 1px solid $color-white;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: $duration;
                }

                &:before {
                    display: none;
                }
            }
        }

        .nav-main-link {
            position: relative;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            height: 90px;
            padding: 0;
            margin: 0 0 0 40px;
            color: $color-white;
            text-transform: uppercase;
            font-size: 1.125rem;
            line-height: 1.25rem;
            font-weight: $font-weight-extrabold;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 15px;
                width: 45px;
                height: 5px;
                background: url(/wp-content/themes/pipriac/assets/src/images/sprite-images/shapes/Ligne-accesrapides-rose.svg) no-repeat;
                background-size: contain;
                opacity: 0;
                transition: $duration;
            }

            span {
                display: block;
                font-weight: $font-weight-light;
            }
        }

        .sub-menu {
            display: none;
            position: absolute;
            left: 0;
            width: 100%;
            padding: 50px 0 60px;
            
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: -1000px;
                height: 100%;
                width: 5000px;
                background-color: rgba(15, 15, 15, .95);
                z-index: -1;
            }

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
            }

            .nav-child-item, .nav-grandchild-item {
                display: inline-flex;
                width: 100%;
                margin: -1px; // Fix space beetween inline-flex
                padding: 0;
                line-height: initial;

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    min-height: 50px;
                    padding: 10px 30px 10px 0;
                    margin-bottom: 1px; // Fix space beetween inline-flex
                    border-bottom: 1px solid rgba(255,255,255,.5);
                    color: $color-white;
                    font-weight: $font-weight-bold;

                    &::before {
                        content: "";
                        display: inline-block;
                        vertical-align: middle;
                        width: 0;
                        height: 5px;
                        margin-right: 10px;
                        background: url(/wp-content/themes/pipriac/assets/src/images/sprite-images/shapes/Ligne-accesrapides-rose.svg) no-repeat;
                        background-size: contain;
                        flex: none;
                        transition: all $duration;
                    }

                    &:hover {
                        &::before {
                            width: 45px;
                        }
                    }
                }
            }

            .nav-grandchild-item {
                a {
                    display: flex;
                    padding: 10px 0 10px 15px;
                    border-bottom: 0;
                    font-weight: $font-weight;
                    text-transform: initial;
                    line-height: initial;

                    &::before {
                        background: url(/wp-content/themes/pipriac/assets/src/images/sprite-images/shapes/Ligne-accesrapides.svg) no-repeat;
                        background-size: contain;
                    }
                } 
            }
        }
    }
}

// Responsive button
.nav-main_button {
    display: none;
}

// Responsive button label
.nav-main_icon {
    display: none;
    align-items: center;
    cursor: pointer;
    padding: 10px 20px;
    color: $color-white;

    .navicon {
        position: relative;
        display: block;
        height: 1px;
        top: -11px;
        width: 20px;
        margin-right: 25px;
        background: $color-white;
        transition: all $duration ease-out;

        &:before, 
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background: $color-white;
            transition: all $duration ease-out;
        }

        &:before {
            top: 7px;
        }

        &:after {
            top: -7px;
        } 
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    #nav-main {
        .menu__nav__list {
            padding-left: 240px;
        }

        .nav-main-item {
            &:last-child .nav-main-link:after {
                width: 140px;
            }

            &:first-child {
                .nav-main-link {
                    margin: 0 0 0 30px;
                }
            }

            .nav-main-link {
                margin: 0 0 0 35px;
                font-size: 1rem;
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    #nav-main {
        position: relative;    
        padding-right: 140px;

        .container--nav {
            position: relative;
        }

        .menu__nav__list {
            display: flex;
            flex-flow: column;
            position: absolute;
            top: 75px;
            width: 100%;
            margin: auto;
            padding: 30px 0 0;
            
            
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: -1000px;
                height: 100%;
                width: 3000px;
                background-color: rgba(15, 15, 15, .95);
                z-index: -1;
            }
        }

        .nav-main-item {
            &:first-child {
                .nav-main-link {
                    margin: 0;
                }
            }

            &.active {
                .nav-main-link {
                    &:before {
                        opacity: 1!important;
                    }
                }
            }

            .nav-main-link {
                position: relative;
                display: flex;
                align-items: flex-start;
                width: 100%;
                max-width: 100%;
                height: 60px;
                padding: 0 20px;
                margin: 0;
                border-bottom: 1px solid $color-white;
                color: $color-white;
                text-transform: uppercase;

                &:before {    
                    opacity: 0!important;
                    bottom: 25px;
                }
            }

            &:last-child {
                .nav-main-link {
                    border-bottom: 0;

                    &:after {
                        display: none;
                    }

                    &:before {
                        display: inline-block;
                    }
                }
            }

            &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
                .sub-menu {
                    display: none;
                }
            }

            &.active,
            &.menu-item-has-children.active {

                .nav-main-link {
                    border-bottom: 1px solid $color-gray;
                    color: $color-white;
                    padding: 0 20px 0 60px;
                }

                .sub-menu {
                    display: flex;
                    position: relative;
                    padding: 5px 10px;
                    box-shadow: none;

                    &:after {
                        display: none;
                    }

                    .nav-drop {
                        column-count: 1;
                    }

                    .nav-grandchild-item {
                        a {
                            padding: 10px;
                        }
                    }

                    li:last-child {
                        a {
                            border-bottom: 0;

                            &::before {
                                display: none;
                            }

                            &::after {
                                top: 50%;
                                transform: translate(0,-50%);
                            }
                        }
                    }
                }
            }

        }

        // Responsive button
        .nav-main_button {
            ~ .menu {
                clear: both;
                transform: scaleY(0);
                transform-origin: 50% 0;
                transition:transform .3s ease;
            }

            &:checked {
                ~ .menu {
                    transform: scaleY(1);
                }
                ~ .nav-main_icon {
                    .navicon {
                        background: $color-bg--transparent;

                        &:before {
                            transform: rotate(-45deg);
                        }

                        &:after {
                            transform: rotate(45deg);
                        }
                    }

                    &:not(.steps) {
                        .navicon {
                            &:before,
                            &:after {
                                top: 0;
                            }
                        }
                    }
                }
            }
        }

        // Responsive button label
        .nav-main_icon {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            width: 100%;
            height: 75px;
            margin: 0;
            padding: 20px 0 0;
            font-size: 1rem;
            font-weight: $font-weight-extrabold;
            text-transform: uppercase; 
        }
    }
    
}


// 640
@media screen and (max-width: $small) {
    #nav-main {
        padding-right: 95px;

        .nav-main_icon {
            height: 65px;
        }

        .menu__nav__list {
            top: 65px;
        }
    }
}
