.header {
    background-color: rgba(15, 15, 15, .5);
    position: fixed;
    z-index: 12;
    width: 100%;
    transition: all $duration ease-out;

    &--dark {
        background-color: $color-dark;
    }

    &__content {
        display: flex;
        align-items: center;
        height: 90px;
        position: relative;
    }

    .identity {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 280px;
        height: 115px;
        background-color: $color-white;
        filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.1));    
        z-index: 1;
        
        &__title {
            font-size: 0;
            line-height: 0;
        }

        img {
            width: 210px;
            height: 75px;
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}


.tools {
    display: flex;
    position: absolute;
    right: 0;
    z-index: 1;

    .tool {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        
        svg {
            width: 40px;
            height: 40px;
            transition: all $duration;
        }
    }

    &__accessibility,
    &__search {
        height: 40px;
        margin: 0 0 0 10px;

        svg {
            fill: $color-text;
        }
    }

    &__accessibility {
        width: 40px;
        border-radius: $border-radius--round;
        background-color: $color-white;
        border-color: $color-white;

        &:hover {
            background-color: $color-dark;
            border-color: $color-dark;

            svg {
                fill: $color-white;
            }
        }
    }

    &__search {
        width: 170px;

        &:hover {
            background-color: $color-white;
            border-color: $color-white;
            color: $color-text;

            svg {
                fill: $color-text;
            }
        }

        svg {
            margin-left: -15px;
        }

        &--disabled {
            pointer-events: none;
            opacity: .2;
        }
    }

    &__cni {
        position: fixed;
        right: -1px;
        top: 130px;
        background-color: $color-dark;
        border: 1px solid $color-gray;
        height: 60px;
        width: 140px;
        border-radius: 30px 0px 0px 30px;
        padding: 0 10px;
        //transform: translateX(90px);

        &:hover {
            background-color: $color-main;
            //transform: translateX(0);

            span {
                color: $color-text;
            }

            svg {
                fill: $color-text;
            }
        }

        svg {
            fill: $color-white;
            transition: $duration;
        }

        span {
            color: $color-white;
            text-transform: uppercase;
            font-size: $font-size--text-small;
            font-weight: $font-weight-medium;
            line-height: 1rem;
            transition: $duration;
        }
    }
}

body.admin-bar {
    .tools__cni {
        top: 162px;
    }
}

iframe.goog-te-menu-frame.skiptranslate {
    position: fixed;
    top: 230px !important;
    left: calc(100% - ((100% - 1200px) / 2) - 335px) !important;
}

.header--fixed {
    background-color: rgba(15, 15, 15, .8);
    position: fixed;

    &.header--dark {
        background-color: $color-dark;
    }
}

body:not(.home) {
    .header {
        background-color: $color-dark;
        position: relative;

        &--fixed {
            background-color: rgba(15, 15, 15, .8);
            position: fixed;
        }
    }
}


//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border-color: $btn-privacy-border-color;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $btn-privacy-bg--hover;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover; 
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 960px)/2 - 335px)!important;
        top: 230px!important;
    }

    .header {
        .identity {
            width: 240px;
            height: 115px;
        }
    }

    .tools {
        &__search {
            width: 40px;

            span {
                display: none;
            }

            svg {
                margin-left: 0;
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 640px)/2 - 335px)!important;
        top: 240px!important;
    }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }

    .header {
        position: relative;
        background-color: $color-dark;
    }

    .tools {
        &__cni {
            display: none!important;
        }
    }
  
}


// 640
@media screen and (max-width: $small) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute!important;
        left: calc(100% - (100% - 300px/2))!important;
        top: 280px!important;
    }

    .header {
        &__content {
            height: 65px;
        }

        .identity {
            width: 140px;
            height: 72px;

            img {
                width: 120px;
                height: 42px;
            }
        }
    }

    .tools {
        &__accessibility,
        &__search {
            width: 38px;
            height: 38px;
            margin: 0 0 0 8px;

            svg {
                width: 38px;
                height: 38px;
            }
        }
    }

}
