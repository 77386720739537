.cover {
    position: relative;
    background-color: $color-light;
    padding: 80px 0 0;

    &__shape {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 150px;
        width: 100%;
        background: url(/wp-content/themes/pipriac/assets/src/images/sprite-images/shapes/BG-ALU.svg) no-repeat;
        z-index: 5;    
        transform: scaleX(-1);
    }

    > .container {
        display: flex;
        flex-wrap: wrap;
    }

    &__image-wrapper {
        width: 570px;
        min-height: 500px;
        background: $color-bg--image;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        box-sizing: content-box;
        padding: 25px 0 75px;
        width: 100%;

        .cover {
            &__intro {
                max-width: 785px;
            }
        }

        &--image {
            padding: 70px 0 0 40px;
            width: 500px;

            .cover {
                &__intro {
                    max-width: 100%;
                }
            }
        }
    }

    &__title {
        margin: 0;
        line-height: 3.125rem;
    }

    &__intro {
        color: $color-text;
        font-size: 1.25rem;
        line-height: 1.563rem;
        font-weight: $font-weight-medium;
        margin: 20px 0 0;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================
// min 1500
@media screen and (min-width: 1500px) {
    .cover {
        &__shape {
            height: 200px;
        }
    }
}

// 2000
@media screen and (max-width: 2000px) {
    .cover {
        &__shape {
            background-size: cover;
        }
    }
}

// 1200
@media screen and (max-width: $large) {

    .cover { 
        &__shape {
            height: 120px;
        }

        &__image-wrapper {
            width: 420px;
            min-height: 430px;
        }

        &__informations {    
            &--image {
                padding: 0px 0 0 20px;
                width: 430px;
            }
        }
    }

}
// 960
@media screen and (max-width: $medium) {

    .cover {
        &__shape {
            height: 95px;
        }

        > .container {
            flex-direction: column;
        }

        &__image-wrapper {
            width: 420px;
            height: 360px;
            min-height: 0;
        }

        &__informations {    
            &--image {
                padding: 20px 0 70px;
                width: 430px;
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__shape {
            height: 65px;
        }

        &__image-wrapper {
            width: 100%;
            height: 270px;
        }

        &__informations {    
            &--image {
                padding: 20px 0 70px;
                width: 100%;
            }
        }
    }

}
