.footer {
    .button {
        height: 40px;
        padding: 0;
    }

    &__top {
        background-color: $color-dark;
        padding: 70px 0;
    }

    &__infos {
        display: flex;
        color: $color-white;
        font-size: $font-size--text-small;

        &__title {
            font-size: $font-size--4;
            line-height: 1.563rem;
            margin: 0 0 15px;
        }

        &__logo {
            display: flex;
            flex-direction: column;
            width: 250px;
            margin-right: 75px;

            img {
                width: 230px;
                height: 85px;
            }
        }

        &__address {
            width: 250px;
            margin-right: 75px;
        }

        &__hours {
            width: 238px;
            margin-right: 60px;
        }

        &__contact,
        &__phone {
            width: 100%;
        }

        &__contact {
            margin: 25px 0 0;

            &:hover {
                svg {
                    path {
                        stroke: $color-white;
                    }
                }
            }

            svg {
                width: 20px;
                height: 20px;
                margin-left: 10px;
            }
        }

        &__phone {
            background-color: $color-bg--transparent;
            border-color: $color-white;
            color: $color-white;
            margin: 30px 0 0;

            &:hover {
                background-color: $color-white;
                border-color: $color-white;
                color: $color-text;
            }
        }

        &__rs {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .button {
                width: 40px;
                border-radius: $border-radius--round;
                margin: 0;

                svg {
                    width: 40px;
                    height: 40px;
                }

                &:nth-child(2) {
                    margin: 15px 0;
                }
            }
        }

        &__links-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            flex: 1;

            a {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 15px 0;

                &:before {
                    content: '';
                    position: absolute;
                    width: calc(100% + 20px);
                    height: calc(100% + 20px);
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 5px;
                    border: 1px solid $color-white;
                    opacity: 0;
                    transition: $duration;
                }

                &:hover {
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &__menu {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;

        &__item {
            margin: 0 40px 0 0;

            &:last-child {
                margin: 0;

                .footer {
                    &__menu {
                        &__link {
                            padding: 0;
        
                            &:after {
                                display: none;
                            }

                            &:hover,
                            &:focus {                
                                &:before {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        &__link {
            position: relative;
            display: flex;
            color: $color-text;
            padding-right: 40px;

            &:hover,
            &:focus {
                color: $color-text;

                &:before {
                    width: calc(100% - 40px);
                }
            }

            &:before,
            &:after {
                content: '';
                position: absolute;
                background-color: $color-dark;
            }

            &:before {
                bottom: 0;
                left: 0;
                width: 0;
                height: 1px;
                transition: $duration;
            }

            &:after {
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 18px;
            }
        }
    }
}

// 1200
@media screen and (max-width: $large) {
    .footer {
        &__infos {
            &__logo {
                width: 200px;
                margin-right: 40px;

                img {
                    width: 200px;
                    height: 75px;
                }
            }

            &__address {
                width: 210px;
                margin-right: 30px;
            }

            &__hours {
                margin-right: 30px;
            }

            &__contact {
                margin: 35px 0 0;
            }
        }

        &__menu {    
            &__item {
                margin: 0 20px 0 0;
    
                &:last-child {
                    margin: 0;
    
                    .footer {
                        &__menu {
                            &__link {
                                padding: 0;
                            }
                        }
                    }
                }
            }
    
            &__link {
                padding-right: 20px;
    
                &:hover,
                &:focus {   
                    &:before {
                        width: calc(100% - 20px);
                    }
                }
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer {
        &__top {
            padding: 60px 0;
        }

        &__infos {
            flex-direction: column;
            align-items: center;
            text-align: center;

            &__text {
                max-width: 238px;
                margin: auto;
            }

            &__logo,
            &__address,
            &__hours {
                width: 300px;
                margin: 0 auto 35px;
            }

            &__logo {
                img {
                    margin: auto;
                }
            }

            &__rs {
                flex-direction: row;
                margin-bottom: 35px;

                .button {
                    &:nth-child(2){
                        margin: 0 15px;
                    }
                }
            }

            &__links-wrapper {
                align-items: center;
            }
        }

        &__menu {
            padding: 0 20px;
            height: 120px;
            flex-wrap: wrap;

            &__item {    
                display: flex;
                align-items: center;
                height: 60px;

                &:nth-child(3) {
                    margin: 0;
    
                    .footer {
                        &__menu {
                            &__link {
                                padding: 0;
            
                                &:after {
                                    display: none;
                                }
    
                                &:hover,
                                &:focus {                
                                    &:before {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .footer {
        &__menu {
            height: 240px;
            padding: 0;

            &__item {    
                &:nth-child(odd) {
                    margin: 0 40px 0 0;
    
                    .footer {
                        &__menu {
                            &__link {
                                padding: 0 40px 0 0;
            
                                &:after {
                                    display: block;
                                }
    
                                &:hover,
                                &:focus {                
                                    &:before {
                                        width: calc(100% - 40px);
                                    }
                                }
                            }
                        }
                    }
                }

                &:nth-child(even) {
                    margin: 0;
    
                    .footer {
                        &__menu {
                            &__link {
                                padding: 0;
            
                                &:after {
                                    display: none;
                                }
    
                                &:hover,
                                &:focus {                
                                    &:before {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}